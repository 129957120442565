.pulsating_logo {
    animation: pulse_logo 2s infinite;
}

@keyframes pulse_logo {
    0% {
        opacity: 0.75;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.75;
    }
}
